import { StaticImageData } from "next/image";
import { forwardRef, HTMLAttributes, ReactNode } from "react";
import { CSSProp } from "styled-components";
import tw from "twin.macro";

import { Avatar } from "../atoms/Avatar";
import { ImageProps } from "../atoms/Image";
import { Typography } from "../atoms/Typography";

interface ListItemImageProps {
  image?: string | StaticImageData;
  fallbackImage?: ImageProps["fallback"];
  size?: number;
  renderImage?: ReactNode;
}

/**
 * @param image
 * @param renderImage
 * @param size
 */
const ListItemImage = ({
  image,
  fallbackImage,
  renderImage,
  size,
}: ListItemImageProps) => {
  return (
    <div tw="mr-[15px] relative">
      {image && (
        <Avatar
          tw="rounded"
          src={image}
          fallback={fallbackImage}
          size={size || 64}
        />
      )}
      {renderImage && renderImage}
    </div>
  );
};

interface ListItemTitleProps {
  title?: string;
  renderTitle?: ReactNode;
  maxLength?: number;
  className?: string;
}

/**
 * @param title Optional[string]
 * @param renderTitle Optional[JSX.Element]
 * @param mexLength Optional[number]
 * @param className Optional[string]
 */
const ListItemTitle = ({
  title,
  renderTitle,
  maxLength,
  className,
}: ListItemTitleProps) => {
  return (
    <>
      {renderTitle && renderTitle}
      {title && (
        <Typography className={className} maxLength={maxLength}>
          {title}
        </Typography>
      )}
    </>
  );
};
interface ListItemContentProps {
  text?: string;
  renderItem?: ReactNode;
  maxLength?: number;
  className?: string;
}

/**
 * @param text Optional[string]
 * @param renderItem Optional[JSX.Element]
 * @param maxLength Optional[number]
 * @param className Optional[string]
 */
const ListItemContent = ({
  text,
  renderItem,
  maxLength,
  className,
}: ListItemContentProps) => {
  return (
    <>
      {renderItem && renderItem}
      {text && (
        <Typography className={className} maxLength={maxLength}>
          {text}
        </Typography>
      )}
    </>
  );
};

export interface Props extends HTMLAttributes<HTMLLIElement> {
  image?: string | StaticImageData;
  fallbackImage?: ImageProps["fallback"];
  title?: string;
  text?: any;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  classes?: {
    title?: CSSProp;
    text?: CSSProp;
  };
  renderImage?: ReactNode;
  renderTitle?: ReactNode;
  renderItem?: ReactNode;
  imageSize?: number;
  titleLength?: number;
  textLength?: number;
  direction?: "column" | "row";
}

/**
 * @param image Optional[string]
 * @param fallbackImage Optional[string]
 * @param title Optional[string]
 * @param text Optional[string]
 * @param disabled Optional[string] default: false
 * @param onClick Optional[string]
 * @param className Optional[string]
 * @param classes Optional[string]
 * @param renderImage Optional[JSX.Element]
 * @param renderTitle Optional[JSX.Element]
 * @param renderItem Optional[JSX.Element]
 * @param imageSize Optional[number]
 * @param titleLength Optional[string]
 * @param textLength Optional[string]
 * @param direction Optional["column" | "row"]
 */
export const ListItem = forwardRef<HTMLLIElement, Props>(
  (
    {
      image,
      fallbackImage,
      title,
      text,
      disabled,
      onClick,
      className,
      classes,
      renderImage,
      renderTitle,
      renderItem,
      imageSize,
      titleLength,
      textLength,
      direction,
      ...rest
    },
    ref
  ) => {
    const withImage = image || renderImage;
    const isVertical = direction === "column" || withImage;
    const withText = title && (text || renderItem);

    const handleClick = () => {
      if (onClick) onClick();
    };

    return (
      <li ref={ref} {...rest}>
        <button
          type="button"
          tw="w-full py-5 px-4 flex items-center border-b border-solid border-gray"
          css={[
            onClick ? tw`cursor-pointer` : tw`cursor-default`,
            onClick && disabled && tw`cursor-not-allowed`,
          ]}
          className={className}
          onClick={handleClick}
          disabled={onClick && disabled}
        >
          {withImage && (
            <ListItemImage
              image={image}
              fallbackImage={fallbackImage}
              renderImage={renderImage}
              size={imageSize}
            />
          )}
          <div
            tw="flex items-center flex-1 overflow-hidden"
            css={[
              isVertical && tw`flex-col items-start`,
              withText && tw`justify-between`,
            ]}
          >
            <ListItemTitle
              title={title}
              renderTitle={renderTitle}
              maxLength={titleLength}
              tw="font-normal text-xs leading-5 tracking-[.35px] w-full whitespace-pre-wrap"
              css={[isVertical && tw`mb-2`, classes?.title]}
            />
            <ListItemContent
              text={text}
              renderItem={renderItem}
              maxLength={textLength}
              tw="text-2xs whitespace-normal w-full mb-0.5 leading-[14px] text-secondary"
              css={classes?.text}
            />
          </div>
        </button>
      </li>
    );
  }
);

ListItem.displayName = "ListItem";
