import { useRef, useCallback } from "react";

/**
 * スクロールやinputなど高頻度で発火されるイベントにおいて、そのイベントが終了したタイミングで任意の処理を実行したい場合に使用する
 * e.g. スクロールが終わったとき、キーボード入力が終わったとき
 *
 * @param eventName string イベント名。console.logに出力されるデバッグ用。
 * @param onEvent function イベント発火時に実行する処理
 * @param onEventEnd function イベント終了時に実行する処理
 * @param handleEventEndInterval number 連続するイベントのうち、最後に発火したイベントを基準にonEventEndが発火するまでの時間。ms単位。
 */
export function useEventEnd<T>({
  eventName,
  onEvent,
  onEventEnd,
  handleEventEndInterval = 500,
}: {
  eventName?: string;
  onEvent?: (e: T) => void;
  onEventEnd: (e: T) => void;
  handleEventEndInterval?: number;
}) {
  // eslint-disable-next-line no-undef
  const timer = useRef<NodeJS.Timeout>();

  const handleEventEnd = useCallback(
    (e: T) => {
      if (process.env.NEXT_PUBLIC_DEBUG && eventName)
        console.log(`[INFO] ${eventName}End`);
      onEventEnd(e);
    },
    [eventName, onEventEnd]
  );

  const handleEvent = useCallback(
    (e: T) => {
      if (process.env.NEXT_PUBLIC_DEBUG && eventName)
        console.log(`[INFO] ${eventName}`);
      if (onEvent) onEvent(e);

      clearTimeout(timer.current);
      timer.current = setTimeout(
        () => handleEventEnd(e),
        handleEventEndInterval
      );
    },
    [eventName, onEvent, handleEventEnd, handleEventEndInterval]
  );

  return {
    handleEvent,
  };
}
