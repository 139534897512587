import { IsWaitingStatusKeys } from "shared/models/FortuneTeller";
import { FortuneTeller } from "shared/types";

export const fortuneTellerHelper = (fortuneTeller: FortuneTeller) => ({
  isWaiting: (key: IsWaitingStatusKeys) => {
    if (!fortuneTeller) return false;

    switch (key) {
      case "waitingFlag":
        return fortuneTeller.waitingFlag;
      case "telWaitingFlag":
        return fortuneTeller.telWaitingFlag;
      case "both":
        return fortuneTeller.waitingFlag && fortuneTeller.telWaitingFlag;
      case "any":
        return fortuneTeller.waitingFlag || fortuneTeller.telWaitingFlag;
      default:
        return false;
    }
  },
  isChatAvailable:
    fortuneTeller &&
    fortuneTeller.havePublishedChat &&
    fortuneTeller.chatAcceptFlag &&
    !fortuneTeller.isBlocked &&
    !fortuneTeller.isBusy,
});
