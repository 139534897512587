import throttle from "lodash/throttle";
import { ForwardedRef, forwardRef, ReactElement, ReactNode } from "react";
import { css } from "styled-components";
import { ref } from "yup";
import { useEventEnd } from "../../hooks/useEventEnd";

interface Props<T> {
  data: Array<T>;
  keyName?: string;
  onClick?: (data: T, index?: number) => void;
  onScroll?: () => void;
  onScrollEnd?: () => void;
  renderItem?: (data: T, index?: number) => ReactNode;
  className?: string;
}

type CarouselType = <T extends { [key: string]: any }>(
  props: Props<T> & { ref?: ForwardedRef<HTMLUListElement> }
) => ReactElement;

export const Carousel: CarouselType & { displayName?: string } = forwardRef(
  (
    { data, keyName, renderItem, onClick, onScroll, onScrollEnd, className },
    ref
  ) => {
    function handleClick<T>(row: T, index: number) {
      if (onClick) onClick(row, index);
    }

    const handleScroll = throttle(() => {
      if (onScroll) onScroll();
    }, 250);

    const handleScrollEnd = () => {
      if (onScrollEnd) onScrollEnd();
    };

    const { handleEvent } = useEventEnd({
      onEvent: handleScroll,
      onEventEnd: handleScrollEnd,
    });

    if (!data) return null;

    return (
      <ul
        ref={ref}
        tw="flex pl-5 overflow-x-scroll overflow-y-hidden mt-4"
        css={css({
          "::-webkit-scrollbar": { display: "none" },
        })}
        className={className}
        onScroll={handleEvent}
      >
        {data.map((row, index) => (
          <li key={index} tw="mr-3">
            {renderItem ? (
              renderItem(row, index)
            ) : (
              <button type="button" onClick={() => handleClick(row, index)}>
                {row[keyName]}
              </button>
            )}
          </li>
        ))}
      </ul>
    );
  }
);

Carousel.displayName = "Carousel";
